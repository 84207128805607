<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/enroll-form.css";
</style>
<style type="text/css" scoped>
  .modal-title /deep/ {
    text-align: center !important;
    width: 100% !important;
    padding: auto !important;
  }

  /deep/.el-select.blueBorder .el-input__inner {
    border-color: rgb(23, 118, 210);
    height: 30px;
  }

  /deep/.el-form-item {
    margin-bottom: 0;
  }

  /deep/.el-transfer-panel__list,
  /deep/.el-transfer-panel__body {
    height: 400px;
  }

  .guding.top-guding {
    width: 30px;
  }

  @media screen and (max-width: 576px) {
    .modal-dialog {
      margin: 0 auto !important;
    }
  }
</style>
<script>
  import {
    chooseInfoMethods
  } from "@/state/helpers";

  import Layout from "@/views/layouts/main";
  import CheckHeader from "@/components/check-header";
  import Person from "./person";
  import EditApplyInfo from "@/components/editApplyInfo";
  import ChangeExam from "@/components/change-exam.vue";
  import checkQuery from "@/components/table/check-query.vue";
  import studentPrints from "@/components/print/studentPrints.vue";
  import {
    getRegisterUserList,
    deleteRegisterUser,
  } from "@/api/admin/register/registerUser.js";
  import {
    exportBmkFields,
    saveFileds
  } from "@/api/common/public";
  import {
    getExamEasy,
    getNowExam
  } from "@/api/admin/exam/examRecord.js";
  import $ from "jquery";

  /**
   * Dashboard component
   */
  export default {
    components: {
      Layout,
      CheckHeader,
      EditApplyInfo,
      Person,
      ChangeExam,
      checkQuery,
      studentPrints,
    },
    data() {
      return {
        title: "考试报名信息审核  / ",
        subTitle: "切换报名",
        ksbmbh: "",
        ksmc: "",
        kmxzfs: null,
        checkType: "",
        checDiv: false,
        editDiv: false,
        exportTitle: "报名摘要导出",
        module: "BMZYB",
        isPhoto: "",
        items: [{
            text: "用户首页",
            href: "/admin",
          },
          {
            text: "报名审核",
            active: true,
          },
        ],
        tabDivShow: 0,
        defaultvalue: 1,
        years: [],
        currentpage: 1,
        showmodal: false,
        select: {},
        tableTool: {
          mzmc: 1,
          zgxlmc: 1,
          zgxwmc: 1,
          zhbyxxmc: 1,
          sfzjlxmc: 0,
          sfzjh: 1,
          zzmmmc: 0,
          hyztmc: 0,
          jkzkmc: 0,
          gjdqmc: 0,
          hjszdmc: 0,
          jg: 0,
          gatqwmc: 0,
          xyzjmc: 0,
          yddh: 0,
          dzxx: 0,
          zp: 1,
          xl: 1,
          zg: 1,
          jf: 1,
          jm:0,
          zt: 1,
          bj: 0,
          sc: 0,
          sh: 1,
        },
        gudingFileds: [{
            label: "报考单位",
            key: "bkdwzy",
          },
          {
            label: "报考专业",
            key: "bkgwxk",
          },

          {
            label: "档案号",
            key: "dah",
          },
          {
            label: "姓名",
            key: "xm",
          },
          {
            label: "出生年月",
            key: "csrq",
          },
          {
            label: "身份证号",
            key: "sfzjh",
          },
          {
            label: "报考时间",
            key: "create_time",
          },
          {
            label: "报考考区",
            key: "kqmc",
          },
          {
            label: "级别",
            key: "jbmc",
          },
          {
            label: "专业",
            key: "zymc",
          },
          // {
          //   key: 'zgshrzh',
          //   label: '资格审核人账号'
          // },
          {
            key: "xlshzt",
            label: "学历",
          },
        ],
        allData: [
          {
            label: "照片",
            key: "zp",
            fixed: "right",
          },

          {
            label: "资格",
            key: "zg",
            fixed: "right",
          },
          {
            label: "缴费",
            key: "jf",
            fixed: "right",
          },
          {
            label: "减免",
            key: "jm",
            fixed: "right",
          },
          {
            label: "编辑",
            key: "bj",
            fixed: "right",
          },
          {
            label: "删除",
            key: "sc",
            fixed: "right",
          },
          {
            label: "审核",
            key: "sh",
            fixed: "right",
          },
        ],
        checkData: ["sfzjh", "mzmc", "zhbyxxmc", "zp", "zg", "jf", "sh"],
        examInfo: {},
        tableList: [],
        filedList: [],
        bmh: "",
        info: {},
        checkStatus: 0,
        checkMemo: "",
        dwList: [],
        zwList: [],
        showItem: false,
        examList: [],
        selectObj: {},
        examParam: {
          ksnf: new Date().getFullYear(),
          ksmc: "",
        },
        fields: [{
            key: "xm",
            label: "姓名",
          },
          {
            key: "sfzjh",
            label: "身份证号",
          },
          {
            key: "xb",
            label: "性别",
          },
          {
            key: "csrq",
            label: "出生日期",
          },
          {
            key: "mz",
            label: "民族",
          },
          {
            key: "zzmm",
            label: "政治面貌",
          },
          {
            key: "jg",
            label: "籍贯",
          },
          {
            key: "csdmc",
            label: "出生地",
          },
          {
            key: "hkszdmc",
            label: "户籍所在地",
          },
          {
            key: "hyztmc",
            label: "婚姻状态",
          },
          {
            key: "gjdqmc",
            label: "所在国家地区",
          },
          {
            key: "gatqwmc",
            label: "港澳台侨",
          },
          {
            key: "jkzkmc",
            label: "健康状态",
          },
          {
            key: "xyzjmc",
            label: "宗教信仰",
          },
          {
            key: "jtzz",
            label: "家庭住址",
          },
          {
            key: "yzbm",
            label: "邮政编码",
          },
          {
            key: "yddh",
            label: "联系电话",
          },
          {
            key: "dzxx",
            label: "电子信箱",
          },
          {
            key: "zgxlmc",
            label: "最高学历",
          },
          {
            key: "zgxwmc",
            label: "最高学位",
          },
          {
            key: "zhbyxxmc",
            label: "最后毕业学校",
          },
          {
            key: "zhbyzy",
            label: "最后毕业专业",
          },
          {
            key: "zhbyxxlb",
            label: "最后毕业学校类别",
          },
          {
            key: "zhbyxxdq",
            label: "最后毕业学校国家地区",
          },
          {
            key: "zcmc",
            label: "当前职称",
          },
          {
            key: "hdzcsj",
            label: "获得职称时间",
          },
          {
            key: "xgzdw",
            label: "现工作单位",
          },
          {
            key: "szdwzw",
            label: "所在单位职务",
          },
        ],
        pageData: {
          pageNum: 1,
          pageSize: 20,
          total: 0,
          bkgwxkm: "",
          bkdwzym: "",
        },
        isIndeterminate: false,
        checkList: [],
        printList: [],
        isDialog: true,
        flbm: "",
        pageNum: 0,
        pageHeight: 1103,
        pdfHeight: 0,
        pdfNum: 1,
        row: 0,
        oldHeight: 0,
        queryMore:{}
      };
    },
    methods: {
      ...chooseInfoMethods,

      getFileds() {
        // 获取表格字段
        let _this = this;
        let list1 = [];
        let list2 = [];
        let newList = [];
        _this.filedList = [];
        let notShowFileds = ["照片存放地址", "照片审核状态", "照片审核说明", "照片审核人账号", "照片审核人", "照片审核时间", "照片审核IP", "审核错误字段"]
        exportBmkFields(this.ksbmbh).then((res) => {
          if (res.status) {
            list1 = res.data.registerFields;
            list2 = res.data.statusFields;
            newList.push(...list1, ...list2);
            newList.forEach((item) => {
              // if (
              //   !_this.gudingFileds.filter((val) => {
              //     return val["key"] == item.zddm;
              //   })[0]
              // ) {
              if (!notShowFileds.filter(val => {
                  return val == item.zdmc
                })[0]) {
                if (!item.zdmc.endsWith("码")) {
                  _this.filedList.push({
                    label: item.zdmc,
                    key: item.zddm,
                    detail: item,
                  });
                }
              }
              if (!this.tableTool[item.zddm]) {
                this.tableTool[item.zddm] = 0;
              }
            });
            this.allData.push(...this.filedList);
            this.getTool();
          }
        });
      },
      //更多查询
      getListMore(more={}){
        this.queryMore=more?more:{}
        this.getList(more)
      },
      // 获取考生申请列表
      getList(more = {}) {
        this.pageData.ksbmbh = this.ksbmbh;
        let search = Object.assign({}, more, this.pageData);

        // let search = {
        //   ...this.pageData,
        //   ...more,
        // };
        getRegisterUserList(search).then((res) => {
          if (res.status) {
            this.tableList = res.data;
            // console.log(this.tableList, 22);
            this.pageData.total = res.total;
            let leng = this.tableList.length;
            if (leng) {
              this.tableList.forEach((k) => {
                if (k.sjfje) {
                  k.sjfje = k.sjfje / 100;
                }
                if (k.yjfje) {
                  k.yjfje = k.yjfje / 100;
                }
                // 拿到数据后格式化时间
                this.$nextTick(() => {
                  for (let key in k) {
                    if (typeof(k[key]) === "string" && k[key].indexOf("T") == 10) {
                      k[key] = this.formatDateDetails(k[key])
                    }
                  }
                })


              });
            }
          }
        });
      },

      // 删除
      deleteItem(info) {
        this.$confirm(`是否确认删除考生“${info.xm}”的报名申请数据?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          deleteRegisterUser(info.bmh).then((res) => {
            if (res.status) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getList();
            }
          });
        });
      },

      // 显示需要的列
      getTool(e) {
        let check = JSON.parse(JSON.stringify(this.checkData));
        let all = this.tableTool;
        let newList = [];
        let newObj = {
          zp: "zpshzt",
          xl: "xlshzt",
          zg: "zgshzt",
          jf: "jfzt",
          jm:"jmzt",
          zt: 0,
          bj: 0,
          sc: 0,
          sh: 0,
          isSend: 0,
        };
        // console.log(check, all, 111)
        for (var i in all) {
          if (check.indexOf(i) > -1) {
            this.tableTool[i] = 1;
            if (newObj[i] != null) {
              if (newObj[i] != 0) {
                newList.push(newObj[i]);
              }
            } else {
              newList.push(i);
            }
          } else {
            this.tableTool[i] = 0;
          }
        }
        if (this.ksbmbh != "") {
          if ((this.tableTool && !this.tableTool.isSend) || e) {
            this.saveToBase(newList);
          } else {
            this.getList();
          }

        }

        // this.setStore("tableTool", this.tableTool)
        // 解决重叠的问题
        let tab = document.getElementById("tableDiv");
        this.$nextTick(() => {
          if (tab.scrollWidth > tab.offsetWidth) {
            this.tabDivShow = 1;
          } else {
            this.tabDivShow = 0;
          }
        });
        this.showmodal = false;
      },

      // 字段保存到数据库
      saveToBase(val) {
        let ksbmbh = this.ksbmbh;
        if (ksbmbh != '') {
          let querykeys = val.join(",");
          saveFileds(ksbmbh, querykeys).then((res) => {
            if (res.status) {
              this.tableTool.isSend = true;
              this.setStore("tableTool", this.tableTool);
            }
            this.getList();
          });
        }
      },

      checkShow(info, checkType) {
        if (!this.isDialog) {
          window.open(
            `/admin/checkNewWin?isPhoto=${true}&ksbmbh=${this.ksbmbh}&bmh=${
            info.bmh
          }&type=${1}&checkType=${checkType}`,
            "_blank"
          );
        } else {
          this.changesfzjh({
            sfzjh: info.sfzjhEnc,
          });
          this.bmh = info.bmh;
          this.checkType = checkType;
          this.checDiv = true;
        }
      },
      editShow(info) {
        this.bmh = info.bmh;
        this.editDiv = true;
        this.changesfzjh({
          sfzjh: info.sfzjhEnc,
        });
      },

      //获取切换的考试
      getLastExam() {
        getNowExam().then((res) => {
          if (res.status && res.data) {
            this.ksbmbh = res.data.ksbmbh;
            this.ksmc = res.data.ksmc;
            // console.log(res.data.kmxzfs);
            
            this.kmxzfs = res.data.kmxzfs;
            this.flbm = res.data.flbm;
            this.isPhoto = res.data.sfsczp;
            this.examInfo = res.data;
            if (this.kabmbh == "") {
              this.getExamList();
            }
            // this.getList()
            this.getFileds();
          }
        });
      },
      // 获取考试列表
      getExamList() {
        getExamEasy(this.examParam).then((res) => {
          if (res.status) {
            this.ksbmbh = res.data[0].ksbmbh;
            this.ksmc = res.data[0].ksmc;
            this.kmxzfs = res.data[0].kmxzfs;
          }
        });
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val;
        this.getList(this.queryMore);
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val;
        this.getList(this.queryMore);
      },
      getLocalTool() {
        // 获取表格自定义显示的localstore的数据
        let newTool = this.getStore("tableTool");
        if (newTool) {
          if (typeof newTool === "string") {
            newTool = JSON.parse(newTool);
          }
          let box = [];
          for (let i in newTool) {
            if (newTool[i] == 1) {
              box.push(i);
            }
          }
          this.checkData = [];
          this.checkData = box;
          this.tableTool = newTool;
        }
        // this.getTool()
      },
      isDialogChange(e) {
        this.isDialog = e;
      },
      // 全选
      checkAll(e) {
        (this.checkList = e ?
          this.tableList.map((item) => {
            return item.bmh;
          }) : []),
        (this.isIndeterminate = e);
      },
      // 批量打印报名信息
      printAll() {
        let len = this.checkList.length;
        if (len) {
          let bmh = this.checkList.join('_')
          let routeData = this.$router.resolve({ path: '/admin/enrollCheckPrint', query: {bmh: bmh,
              ksbmbh: this.ksbmbh} });
          window.open(routeData.href, '_blank');
          return

          $("#pdf").css("display", "");
          this.printList = this.checkList;
          let ids = [];
          this.printList.forEach((v) => {
            ids.push("pdf" + v + 1);
          });
          let str = ids.join(",");
          this.$nextTick(() => {
            this.$confirm(`是否打印所选考生报考信息,共${len}条数据?`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
              .then(() => {
                // setTimeout(function(){

                // },100)
                this.$message({
                  type: "success",
                  message: "数据生成中，请耐心等待",
                });
                $("#printId").css("display", "");
                this.printList.forEach((v) => {
                  ids.push("pdf" + v + 1);
                  this.toPdfHtml("printId", "pdf" + v + 1, "pdf" + v);
                });
                $("#pdf").css("display", "none");

                this.ExportSavePdf("报名信息表", str, 0, true, "printId");
              })
              .catch(() => {
                $("#pdf").css("display", "none");
                this.$message({
                  type: "info",
                  message: "已取消打印",
                });
              });
          });
        } else {
          this.$message({
            type: "warning",
            message: "请选择要打印的数据！",
          });
        }
      },

      // 打印前的处理
      toPdfHtml(id, id1, id2) {
        let width = $("#" + id).outerWidth();
        this.pageHeight = Math.floor((width / 210) * 297);
        if ($("#" + id1).length > 0) {
          return false;
        }
        this.pdfNum = 1;
        this.pdfHeight = 0;
        $("#" + id).append("<div id='" + id1 + "'></div>");
        $("#" + id1).append(
          "<div class='pdf' id='" +
          id1 +
          "1' page='1' style='height:" +
          this.pageHeight +
          "px'></div>"
        );
        //
        if ($("#" + id2 + " .kstitle").length > 0) {
          this.addItem(
            $("#" + id2 + " .kstitle"),
            $("#" + id2 + " .kstitle").outerHeight(true),
            id1
          );
        }
        if ($("#" + id2 + " .jbxx").length > 0) {
          this.addItem(
            $("#" + id2 + " .jbxx .title"),
            $("#" + id2 + " .jbxx .title").outerHeight(true),
            id1
          );
          let itemhead = $("#" + id2 + " .jbxx .value").find(
            ".person-form.outerCotent>.msgContent>div:first-child"
          );
          this.addItem($(itemhead), $(itemhead).outerHeight(true), id1);
          let itemfoot = $("#" + id2 + " .jbxx .value").find(
            ".person-form.outerCotent>.msgContent>div:last-child"
          );
          $(itemfoot)
            .children()
            .children()
            .each((i, item) => {
              this.addItem(item, $(item).outerHeight(), id1);
            });
        }
        if ($("#" + id2 + " .bkxx").length > 0) {
          this.addItem(
            $("#" + id2 + " .bkxx .title"),
            $("#" + id2 + " .bkxx .title").outerHeight(true),
            id1
          );
        }
        if ($("#" + id2 + " .msxx").length > 0) {
          this.addItem(
            $("#" + id2 + " .msxx"),
            $("#" + id2 + " .msxx").outerHeight(true),
            id1
          );
        }
        if ($("#" + id2 + " .bmzt").length > 0) {
          this.addItem(
            $("#" + id2 + " .bmzt"),
            $("#" + id2 + " .bmzt").outerHeight(true),
            id1
          );
        }
      },
      addItem(item, height, id, imgurl) {
        let paddingHeight = 60;
        height = Number(height);
        // 当前pdf高度大于0，且未添加新对象高度前后的页数不一致则换页
        if (
          this.pdfHeight > 0 &&
          parseInt((this.pdfHeight + paddingHeight) / this.pageHeight) !=
          parseInt((this.pdfHeight + height + paddingHeight) / this.pageHeight)
        ) {
          // pdf页数加1
          this.pdfNum += 1;
          this.pdfHeight = (this.pdfNum - 1) * this.pageHeight;
          $("#" + id).append(
            "<div class='pdf' id='" +
            id +
            this.pdfNum +
            "' page='" +
            this.pdfNum +
            "'style='height:" +
            this.pageHeight +
            "px'></div>"
          );
        }

        if (
          $(item).hasClass("msgLabel") &&
          !$(item).hasClass("w100") &&
          this.row == 0
        ) {
          this.oldHeight = height;
          height = 0;
          this.row = 1;
        } else if (
          $(item).hasClass("msgLabel") &&
          !$(item).hasClass("w100") &&
          this.row != 0
        ) {
          this.row = 0;
        }
        if (
          $(item).hasClass("msgLabel") &&
          $(item).hasClass("w100") &&
          this.row != 0
        ) {
          this.pdfHeight += this.oldHeight;
        }

        this.pdfHeight += height;
        // 将内容放入div中
        if ($(item).hasClass("msgLabel")) {
          if ($("#" + id + this.pdfNum + " .flexBox").length) {
            $(item)
              .clone()
              .appendTo($("#" + id + this.pdfNum + " .flexBox"));
          } else {
            $("#" + id + this.pdfNum).append(
              "<div class='d-flex flex-wrap flexBox'></div>"
            );
            $(item)
              .clone()
              .appendTo($("#" + id + this.pdfNum + " .flexBox"));
          }
        } else {
          $(item)
            .clone()
            .appendTo($("#" + id + this.pdfNum));
        }
        $("#" + id + " .pdf").css("padding", "30px 25px");
        $("#" + id + " .pdf").css("color", "black");
        if (imgurl) {
          $("#" + id + " .pdf").css("background-image", "url(" + imgurl + ")");
        }
      },
    },
    mounted() {
      this.getLastExam();
      this.getLocalTool();
    },
    computed: {
      fixedData() {
        let allData = JSON.parse(JSON.stringify(this.allData));
        let tableTool = JSON.parse(JSON.stringify(this.tableTool));
        return allData.filter((k) => {
          return k.fixed == "right" && tableTool[k.key];
        });
      },
    },
  };
</script>

<template>
  <Layout>
    <CheckHeader :title="title" :title2="ksmc" :subTitle="subTitle" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 650px">
          <div class="card-body">
            <checkQuery v-if="kmxzfs != null" :kmxzfs="kmxzfs" v-model="pageData" :flbm="flbm" @getList="getListMore"
              @isDialogChange="isDialogChange" :showDialog="true" :ksbmbh="ksbmbh" :ksmc="ksmc" :checkList="checkList"
              @printAll="printAll">
            </checkQuery>
            <div style="position: relative">
              <div class="table-responsive border" id="tableDiv" style="max-width: 100%; min-height: 200px">
                <el-checkbox-group v-model="checkList">
                  <table class="table light-table table-hover table-bordered table-scroll" id="tabDiv2">
                    <thead class="thead-light">
                      <tr>
                        <th style="width: 55px">
                          <el-checkbox :indeterminate="isIndeterminate" @change="checkAll"></el-checkbox>序号
                        </th>
                        <th style="width: 120px" v-if="kmxzfs == 2">
                          报考单位
                        </th>
                        <th style="width: 200px">
                          {{ kmxzfs == 1 ? "报考专业" : "报考职位" }}
                        </th>
                        <th style="width: 160px" v-if="kmxzfs == 1">档案号</th>
                        <th style="width: 80px">姓名</th>
                        <!-- <th style="width: 85px">出生年月</th> -->
                        <th style="width: 80px">报名来源</th>
                        <th style="width: 120px">报考时间</th>
                        <th style="width: 110px" v-if="kmxzfs == 1">
                          报考考区
                        </th>
                        <th style="width: 60px" v-if="kmxzfs == 1">级别</th>
                        <th style="width: 180px" v-if="kmxzfs == 1">专业</th>
                        <!-- <th style="width: 180px">资格审核人账号</th> -->
                        <!-- <th style="width: 160px">资格审核人姓名</th> -->
                        <th style="width: 110px" v-for="obj in filedList" :key="obj.index" v-if="tableTool[obj.key]">
                          {{ obj.label }}
                        </th>
                        <th class="guding top-guding text-center" style="width: 50px">
                          报名号
                        </th>
                        <th class="guding top-guding text-center" style="width: 43px" v-if="tableTool.zp">
                          照片
                        </th>
                        <!-- <th class="guding top-guding text-center" v-if="tableTool.xl">学历 </th> -->
                        <th class="guding top-guding text-center" style="width: 43px" v-if="tableTool.zg">
                          资格
                        </th>
                        <th class="guding top-guding text-center" style="width: 43px" v-if="tableTool.jf">
                          缴费
                        </th>
                        <th class="guding top-guding text-center" style="width: 43px" v-if="tableTool.jm">
                          减免
                        </th>
                        <!-- <th class="guding top-guding text-center" v-if="tableTool.zt">状态 </th> -->
                        <th class="guding top-guding text-center" style="width: 43px" v-if="tableTool.bj">
                          编辑
                        </th>
                        <th class="guding top-guding text-center" style="width: 43px" v-if="tableTool.sc">
                          删除
                        </th>
                        <th class="guding top-guding text-center" style="width: 43px" v-if="tableTool.sh">
                          审核
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in tableList" :key="index">
                        <td>
                          <el-checkbox :label="item.bmh">{{
                              index +
                              1 +
                              (pageData.pageNum - 1) * pageData.pageSize
                            }}
                          </el-checkbox>
                        </td>
                        <td v-if="kmxzfs == 2">{{ item.bkdwzy }}</td>
                        <td>
                          <div class="line1" :title="item.bkgwxk">
                            {{ item.bkgwxk }}({{ item.bkgwxkm }})
                          </div>
                        </td>
                        <td v-if="kmxzfs == 1">{{ item.dah }}</td>
                        <td>
                          <el-button type="text" size="mini" class="font-blue h30" @click="checkShow(item, '')">
                            {{ item.xm }}
                          </el-button>
                        </td>
                        <!-- <td>{{ item.csrq }}</td> -->
                        <td>{{item.bmly?item.bmly:'网站报名'}}</td>
                        <td>{{ item.create_time?formatDateDetails(item.create_time):""}}</td>
                        <td v-if="kmxzfs == 1">{{ item.kqmc }}</td>
                        <td v-if="kmxzfs == 1">{{ item.bkjbmc }}</td>
                        <td v-if="kmxzfs == 1">{{ item.bkdwzy }}</td>

                        <!-- <td>{{item.zgshrzh}}</td> -->
                        <!-- <td>{{ item.zgshrxm }}</td> -->
                        <td v-for="obj in filedList" :key="obj.index" v-if="tableTool[obj.key]">
                          <div class="line1" :title="item[obj.key]">
                            {{ item[obj.key] }}
                          </div>
                        </td>

                        <td >{{ item.bmhDec }} </td>
                        <td v-if="tableTool.zp" class="tab-icon light-yellow-bg guding top-guding">
                          <span role="button" @click="checkShow(item, 'photo')" title="照片审核">
                            <i v-if="item.zpshzt == 1" class="iconfont icon-a-ze-checkedCopy2 text-success"></i>
                            <i v-else-if="item.zpshzt == 2" class="iconfont icon-ze-clear text-danger"></i>
                            <i v-else class="iconfont icon-icon_minus-circled"></i>
                          </span>
                        </td>

                        <td v-if="tableTool.zg" class="tab-icon light-yellow-bg guding top-guding">
                          <span role="button" @click="checkShow(item, 'quality')" title="资格审核">
                            <i v-if="item.zgshzt == 1" class="iconfont icon-a-ze-checkedCopy2 text-success"></i>
                            <i v-else-if="item.zgshzt == 2" class="iconfont icon-ze-clear text-danger"></i>
                            <i v-else-if="item.zgshzt == 9" class="iconfont icon-a-md-lock_outlineCopy text-danger"></i>
                            <i v-else class="iconfont icon-icon_minus-circled"></i>
                          </span>
                        </td>
                        <td v-if="tableTool.jf" class="tab-icon light-yellow-bg guding top-guding">
                          <i v-if="item.jfzt == 1" class="iconfont icon-a-ze-checkedCopy2 text-success"></i>
                          <i v-else-if="item.jfzt == 2" class="iconfont icon-icon_minus-circled text-danger"></i>
                          <i v-else-if="item.jfzt == 9" class="iconfont icon-bianji text-danger"></i>
                          <i v-else class="iconfont icon-icon_minus-circled"></i>
                        </td>
                        <td v-if="tableTool.jm" class="tab-icon light-yellow-bg guding top-guding">

                            <i v-if="item.jmzt == 1" class="iconfont icon-a-ze-checkedCopy2 text-success"></i>
                            <i v-else-if="item.jmzt == 2" class="iconfont icon-ze-clear text-danger"></i>
                            <i v-else class="iconfont icon-icon_minus-circled"></i>

                        </td>
                        <td v-if="tableTool.bj" class="tab-icon light-pink-bg guding top-guding">
                          <i class="iconfont icon-edit-two" @click="editShow(item)"></i>
                        </td>
                        <td v-if="tableTool.sc" class="tab-icon light-pink-bg guding top-guding">
                          <i class="iconfont icon-riLine-delete-bin-line" @click="deleteItem(item)"></i>
                        </td>
                        <td v-if="tableTool.sh" class="tab-icon light-blue-bg guding top-guding">
                          <i class="iconfont icon-md-input text-info" @click="checkShow(item, '')" title="审核"></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </el-checkbox-group>
                <div class="fixedRight" :style="[{ width: fixedData.length * 43 + 'px' }]" v-if="tabDivShow">
                  <table class="table light-table table-hover table-bordered table-scroll tableCopy" id="tabDiv2">
                    <thead class="thead-light">
                      <tr>
                        <th style="width: 55px">
                          <el-checkbox :indeterminate="isIndeterminate" @change="checkAll"></el-checkbox>
                          序号
                        </th>
                        <th style="width: 120px" v-if="kmxzfs == 2">
                          报考单位
                        </th>
                        <th style="width: 200px">
                          {{ kmxzfs == 1 ? "报考专业" : "报考职位" }}
                        </th>
                        <th style="width: 160px" v-if="kmxzfs == 1">档案号</th>
                        <th style="width: 80px">姓名</th>
                        <!-- <th style="width: 85px">出生年月</th> -->
                        <th style="width:80px ">报名来源</th>
                        <th style="width:120px ">报考时间</th>
                        <th style="width: 110px" v-if="kmxzfs == 1">
                          报考考区
                        </th>
                        <th style="width: 60px" v-if="kmxzfs == 1">级别</th>
                        <th style="width: 180px" v-if="kmxzfs == 1">专业</th>
                        <!-- <th style="width: 180px">资格审核人账号</th> -->
                        <!-- <th style="width: 160px">资格审核人姓名</th> -->
                        <th style="width: 110px" v-for="obj in filedList" :key="obj.index" v-if="tableTool[obj.key]">
                          {{ obj.label }}
                        </th>

                        <th class="guding top-guding text-center" style="width: 50px">
                          报名号
                        </th>
                        <th class="guding top-guding text-center" style="width: 43px" v-if="tableTool.zp">
                          照片
                        </th>
                        <!-- <th class="guding top-guding text-center" v-if="tableTool.xl">学历 </th> -->
                        <th class="guding top-guding text-center" style="width: 43px" v-if="tableTool.zg">
                          资格
                        </th>
                        <th class="guding top-guding text-center" style="width: 43px" v-if="tableTool.jf">
                          缴费
                        </th>
                        <th class="guding top-guding text-center" style="width: 43px" v-if="tableTool.jm">
                         减免
                        </th>
                        <!-- <th class="guding top-guding text-center" v-if="tableTool.zt">状态 </th> -->
                        <th class="guding top-guding text-center" style="width: 43px" v-if="tableTool.bj">
                          编辑
                        </th>
                        <th class="guding top-guding text-center" style="width: 43px" v-if="tableTool.sc">
                          删除
                        </th>
                        <th class="guding top-guding text-center" style="width: 43px" v-if="tableTool.sh">
                          审核
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in tableList" :key="index">
                        <td>
                          <el-checkbox :label="item.bmh">{{
                              index +
                              1 +
                              (pageData.pageNum - 1) * pageData.pageSize
                            }}
                          </el-checkbox>
                        </td>
                        <td v-if="kmxzfs == 2">{{ item.bkdwzy }}</td>
                        <td>
                          <div class="line1" :title="item.bkgwxk">
                            {{ item.bkgwxk }}({{ item.bkgwxkm }})
                          </div>
                        </td>
                        <td v-if="kmxzfs == 1">{{ item.dah }}</td>
                        <td>
                          <el-button type="text" size="mini" class="font-blue h30" @click="checkShow(item, '')">
                            {{ item.xm }}
                          </el-button>
                        </td>
                        <td>{{item.bmly?item.bmly:'网站报名'}}</td>
                        <!-- <td>{{ item.csrq }}</td> -->
                        <td>{{ item.create_time?formatDateDetails(item.create_time):"" }}</td>
                        <td v-if="kmxzfs == 1">{{ item.kqmc }}</td>
                        <td v-if="kmxzfs == 1">{{ item.bkjbmc }}</td>
                        <td v-if="kmxzfs == 1">{{ item.bkdwzy }}</td>

                        <!-- <td>{{item.zgshrzh}}</td> -->
                        <!-- <td>{{ item.zgshrxm }}</td> -->
                        <td v-for="obj in filedList" :key="obj.index" v-if="tableTool[obj.key]">
                          <div class="line1" :title="item[obj.key]">
                            {{ item[obj.key] }}
                          </div>
                        </td>

                        <td >{{ item.bmhDec }} </td>

                        <td v-if="tableTool.zp" class="tab-icon light-yellow-bg guding top-guding">
                          <span role="button" @click="checkShow(item, 'photo')" title="照片审核">
                            <i v-if="item.zpshzt == 1" class="iconfont icon-a-ze-checkedCopy2 text-success"></i>
                            <i v-else-if="item.zpshzt == 2" class="iconfont icon-ze-clear text-danger"></i>
                            <i v-else class="iconfont icon-icon_minus-circled"></i>
                          </span>
                        </td>

                        <td v-if="tableTool.zg" class="tab-icon light-yellow-bg guding top-guding">
                          <span role="button" @click="checkShow(item, 'quality')" title="资格审核">
                            <i v-if="item.zgsdzt == 1" class="iconfont icon-a-md-lock_outlineCopy text-danger"></i>
                            <i v-else-if="item.zgshzt == 1" class="iconfont icon-a-ze-checkedCopy2 text-success"></i>
                            <i v-else-if="item.zgshzt == 2" class="iconfont icon-ze-clear text-danger"></i>
                            <i v-else class="iconfont icon-icon_minus-circled"></i>
                          </span>
                        </td>
                        <td v-if="tableTool.jf" class="tab-icon light-yellow-bg guding top-guding">
                          <i v-if="item.jfzt == 1" class="iconfont icon-a-ze-checkedCopy2 text-success"></i>
                          <i v-else-if="item.jfzt == 2" class="iconfont icon-icon_minus-circled text-danger"></i>
                          <i v-else-if="item.jfzt == 9" class="iconfont icon-bianji text-danger"></i>
                          <i v-else class="iconfont icon-icon_minus-circled"></i>
                        </td>
                        <td v-if="tableTool.jm" class="tab-icon light-yellow-bg guding top-guding">

                          <i v-if="item.jmzt == 1" class="iconfont icon-a-ze-checkedCopy2 text-success"></i>
                          <i v-else-if="item.jmzt == 2" class="iconfont icon-ze-clear text-danger"></i>
                          <i v-else class="iconfont icon-icon_minus-circled"></i>

                        </td>
                        <td v-if="tableTool.bj" class="tab-icon light-pink-bg guding top-guding">
                          <i class="iconfont icon-edit-two" @click="editShow(item)"></i>
                        </td>
                        <td v-if="tableTool.sc" class="tab-icon light-pink-bg guding top-guding">
                          <i class="iconfont icon-riLine-delete-bin-line" @click="deleteItem(item)"></i>
                        </td>
                        <td v-if="tableTool.sh" class="tab-icon light-blue-bg guding top-guding">
                          <i class="iconfont icon-md-input text-info" @click="checkShow(item, '')" title="审核"></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹窗开始 -->
    <!-- 表格列数自定义 -->
    <b-modal id="tableSet" v-model="showmodal" centered title="表格自定义" title-class="font-18" size="lg" hide-footer>
      <div style="padding-left: 85px">
        <el-transfer v-model="checkData" :data="allData" :titles="['所有列', '需要显示的列']" style="min-height: 400px">
        </el-transfer>
      </div>
      <div class="text-center mt-3">
        <button type="button" class="btn btn-info mr-2 w-sm" @click="getTool('1')">
          确定
        </button>
        <button type="button" class="btn btn-secondary w-sm" @click="showmodal = false">
          取消
        </button>
      </div>
    </b-modal>
    <!-- 报名审核弹出 -->
    <b-modal id="person" v-model="checDiv" :title="ksmc" size="xl" title-class="font-18" hide-footer>
      <Person :ksbmbh="ksbmbh" :bmh="bmh" :isPhoto="isPhoto" :checkType="checkType" @success="getList" />
    </b-modal>
    <!-- 编辑审核弹出 -->
    <b-modal id="person" v-model="editDiv" :title="ksmc" size="xl" title-class="font-18" hide-footer>
      <editApply-info :ksbmbh="ksbmbh" :bmh="bmh" :checkType="checkType" />
    </b-modal>

    <!-- 生成打印信息 -->
    <div class="container">
      <div id="pdf" style="display: none">
        <div v-for="x in printList" :key="x.index" :id="'pdf' + x">
          <studentPrints :ksbmbh="ksbmbh" :examInfo="examInfo" :bmh="x" :checkType="checkType" @success="getList"
            ref="studentPrints" />
        </div>
      </div>
      <div id="printId" style="display: none"></div>
    </div>

    <!-- 切换考试报名库 -->
    <ChangeExam :examDivShow="false"></ChangeExam>
  </Layout>
</template>
